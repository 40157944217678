import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './data/store'
import './assets/tailwind.css'
import 'vue-croppa/dist/vue-croppa.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-tour/dist/vue-tour.css'
import VeeValidate from 'vee-validate';
import {Validator} from 'vee-validate';
import Vuetify from 'vuetify'
import VueTour from 'vue-tour'
import {
    Button,
    Message,
    MessageBox,
    Loading,
    Dialog,
    Tabs,
    TabPane,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Collapse,
    CollapseItem,
    Select,
    Option,
    DatePicker,
    Tooltip,
    Progress,
    Pagination,
    Rate,
    Steps,
    Step,
    CheckboxGroup,
    Checkbox,
    Tag,
    Input
} from 'element-ui';
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import {rtdbPlugin} from 'vuefire'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps';
import Croppa from 'vue-croppa';
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LeISL0qAAAAAAsTUjzfmZMP7jW5o1KLZILmUmw8' })

Vue.use(Croppa);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCLScSVyxuUPSW_aDd28Mnqu9XuCgSXidE',
    }
});
Vue.prototype.moment = moment
Vue.use(rtdbPlugin)
Vue.use(VueTour)
Vue.use(VueMeta)
Vue.use(VueSocialSharing);
Vue.component(Input.name,Input)
Vue.component(CheckboxGroup.name,CheckboxGroup)
Vue.component(Steps.name,Steps)
Vue.component(Step.name,Step)
Vue.component(Button.name,Button)
Vue.component(Progress.name, Progress)
Vue.component(Tooltip.name, Tooltip);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option)
Vue.component(Dialog.name, Dialog);
Vue.component(TabPane.name, TabPane);
Vue.component(Tabs.name, Tabs);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(DropdownMenu.name,DropdownMenu)
Vue.component(Pagination.name,Pagination)
Vue.component(Rate.name,Rate)
Vue.component(Checkbox.name,Checkbox)
Vue.component(Tag.name,Tag)
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$ELEMENT = {size: 'medium', zIndex: 3000};
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
import './utils/firebase'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang);
Vue.use(VeeValidate);
Vue.mixin({
    methods: {
        globalScrollToDiv: async function (path, id) {
            if (this.$route.path !== path) {
                await router.push(path)
            }
            const yOffset = -10;
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top - 80 + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
    },
})
Validator.extend('contain_lowercase', {
    getMessage: field => 'The ' + field + ' must contain at least one lowercase letter',
    validate: (value) => {
        return /[a-z]/.test(value);
    }
});
Validator.extend('contain_uppercase', {
    getMessage: field => 'The ' + field + ' must contain at least one uppercase letter',
    validate: (value) => {
        return /[A-Z]/.test(value)
    }
});
Validator.extend('contain_number', {
    getMessage: field => 'The ' + field + ' must contain at least one digit',
    validate: (value) => {
        return /[0-9]/.test(value);
    }
});
Validator.extend('contain_special_character', {
    getMessage: field => 'The ' + field + ' must contain at least one special character',
    validate: (value) => {
        return /[@$!%*#?&.]/.test(value);
    }
});
Validator.extend('valid_website_url', {
    getMessage: field => 'The ' + field + ' must be a valid url',
    validate: (value) => {
        return /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/.test(value);
    }
});
Vue.config.productionTip = false
Vue.use(Vuetify)
const vuetify = new Vuetify({});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
